import React, { Fragment } from "react";
import { graphql } from "gatsby";
import LayoutGeneral from "../components/layout.general";
import { RelatedArticles } from "../components/elements/articles/related-articles.component";
import { MdxRendererLocal } from "../components/mdx/mdx-renderer-local.component";
import { BlockLoader } from "./../components/blocks/block-loader";
import { CourseModuleToc } from "../components/elements/courses/course-module-toc.component";
import { ImageElement } from "../components/elements/image.element";
import { ShareMeElement } from "../components/elements/social/shareme.element";
import { FlyingSalesRep } from "../components/elements/popins/flying-sales-rep.component";

function ArticleTitle({ title, subtitle, lead, image, readingTime, url }) {
  const hasImage = !!image?.childImageSharp;

  return (
    <div className="bg-white relative overflow-hidden">
      {hasImage && (
        <div className="absolute inset-0 z-0 hidden lg:block">
          <div className="w-full h-full z-10 absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-25"></div>
          <ImageElement
            src={image}
            isFluid={true}
            objectFit="cover"
            objectPosition="center center"
            className="h-full w-full z-0"
          />
        </div>
      )}

      <div className={`max-w-7xl mx-auto ${hasImage ? "lg:pt-72" : ""}`}>
        <div
          className={`max-w-3xl py-16 px-4 sm:px-6 lg:px-12 z-10 relative ${
            hasImage ? "lg:bg-white lg:shadow-xl  sm:py-12" : "sm:py-24"
          }`}
        >
          <div>
            {subtitle && (
              <span className="block text-base mb-3 font-semibold text-pink-600 tracking-wide uppercase">
                {subtitle}
              </span>
            )}

            {title && (
              <h1 className="mt-1 text-4xl font-bold font-sans text-gray-900 sm:text-5xl lg:text-6xl">
                {title}
              </h1>
            )}

            {lead && (
              <p className="max-w-2xl mt-5 text-xl text-gray-800">{lead}</p>
            )}

            {readingTime && readingTime?.text && (
              <div className="mt-8 flex items-center">
                <div>
                  <div className="flex items-center space-x-4 divide-x text-sm text-gray-700">
                    <div>
                      <div className="text-xs">Lesezeit:</div>
                      <div className="text-xl leading-loose">
                        {readingTime?.text?.replace("read", "")}
                      </div>
                    </div>
                    <div className="pl-4">
                      <div className="text-xs">Mit Freunden teilen: </div>
                      <div className="text-xl leading-loose text-gray-600">
                        <ShareMeElement
                          url={url}
                          title={title}
                          description={lead}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function ArticleContent({ content, toc = null }) {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="relative px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
        {toc && (
          <div className="md:float-left md:max-w-sm md:mr-12 md:mb-12 md:mt-0 z-10 relative rounded-lg overflow-hidden">
            {toc}
          </div>
        )}

        {content && (
          <div className="mt-6">
            <div className="prose xprose-pink prose-lg text-gray-700 mx-auto">
              <MdxRendererLocal>{content}</MdxRendererLocal>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function BlockArticleWrap({ blocks, page, color, siteConfig }) {
  if (!Array.isArray(blocks)) {
    return null;
  }

  return (
    <Fragment>
      {blocks.map(block => (
        <BlockLoader
          page={page}
          color={color}
          block={block}
          key={block.id}
          siteConfig={siteConfig}
        />
      ))}
    </Fragment>
  );
}

export default function BlogTemplate({ page, pageContext, data, location }) {
  const {
    mdx,
    navigationDe,
    navigationEn,
    footerDe,
    footerEn,
    social,
    relatedArticles,
  } = data || {}; // data.mdx holds your post data

  const { language, wrappingBlocks, color, siteConfig } = pageContext;
  const footer = language.toLowerCase() === "de" ? footerDe : footerEn;
  const navigation =
    language.toLowerCase() === "de" ? navigationDe : navigationEn;

  if (!mdx) {
    return null;
  }

  const {
    fields: { slug, readingTime },
    frontmatter: {
      title,
      headline,
      topic,
      description,
      hero: { foreground_image },
      ads,
    },
    body,
  } = mdx;

  const { flyingSalesRep } = ads || {};

  const url = siteConfig?.siteUrl + slug;

  const seo = {
    title,
    description,
  };

  const enrichedContext = {
    seo,
    ...pageContext,
  };

  return (
    <LayoutGeneral
      pageContext={enrichedContext}
      path={location?.pathname}
      navigation={navigation}
      footer={footer}
      social={social}
    >
      <BlockArticleWrap
        blocks={wrappingBlocks?.pre}
        page={page}
        color={color}
        siteConfig={siteConfig}
      />

      <article>
        <header>
          <ArticleTitle
            title={headline}
            subtitle={topic}
            lead={description}
            image={foreground_image}
            readingTime={readingTime}
            url={url}
          />
        </header>

        <BlockArticleWrap
          blocks={wrappingBlocks?.postTitle}
          page={page}
          color={color}
          siteConfig={siteConfig}
        />

        <ArticleContent
          content={body}
          toc={<CourseModuleToc node={mdx} embedded={true} />}
        />
      </article>

      <BlockArticleWrap
        blocks={wrappingBlocks?.post}
        page={page}
        color={color}
        siteConfig={siteConfig}
      />

      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <RelatedArticles
          topic={topic}
          articles={relatedArticles.edges}
          min={3}
        />
      </div>

      {flyingSalesRep && flyingSalesRep.enabled !== false && <FlyingSalesRep {...flyingSalesRep} />}
    </LayoutGeneral>
  );
}

export const pageQuery = graphql`
  query($slug: String!, $topic: String!, $language: String!) {
    navigationDe: fragmentsYaml(key: { eq: "navigation-reduced" }) {
      key
      id
      data
    }
    footerDe: fragmentsYaml(key: { eq: "footer-navigation" }) {
      key
      id
      data
    }
    navigationEn: fragmentsYaml(key: { eq: "navigation-reduced-en" }) {
      key
      id
      data
    }
    footerEn: fragmentsYaml(key: { eq: "footer-navigation-en" }) {
      key
      id
      data
    }
    social: fragmentsYaml(key: { eq: "social-profiles" }) {
      key
      id
      data
    }
    mdx: mdx(fields: { slug: { eq: $slug } }) {
      body
      tableOfContents
      fields {
        slug
        readingTime {
          text
        }
      }
      frontmatter {
        slug
        title
        headline
        description
        topic
        ads {
          flyingSalesRep {
            firstName
            lastName
            role
            enabled
            trivia
            mission
            company
            phoneNumber
            email
            linkedin
            portrait {
              publicURL
              childImageSharp {
                id
                fluid(maxWidth: 640) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                  srcWebp
                  srcSetWebp
                }
                fixed(width: 640) {
                  base64
                  aspectRatio
                  width
                  height
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                }
              }
            }
            caseOptions {
              option
              key
            }
            caseSolutions {
              key
              response
              followUpCtas {
                link
                text
                primary
                icon
              }
            }
            fallbackCaseSolution {
              key
              response
              followUpCtas {
                link
                text
                primary
                icon
              }
            }
            delay
          }
        }
        hero {
          theme
          background_color
          accent_color
          foreground_image {
            publicURL
            childImageSharp {
              id
              fluid(maxWidth: 2400) {
                base64
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
        }
      }
    }
    relatedArticles: allMdx(
      filter: {
        frontmatter: {
          draft: { ne: true }
          type: { eq: "blog-article" }
          language: { eq: $language }
          topic: { eq: $topic }
        }
        fields: { slug: { ne: $slug } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            headline
            description
            topic
            date(formatString: "DD.MM.YYYY")
            lead_image {
              publicURL
              childImageSharp {
                id
                fluid(maxWidth: 1080) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                  srcWebp
                  srcSetWebp
                }
                fixed(width: 1080) {
                  base64
                  aspectRatio
                  width
                  height
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                }
              }
            }
            hero {
              theme
              background_color
              accent_color
              foreground_image {
                publicURL
                childImageSharp {
                  id
                  fluid(maxWidth: 1080) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                    srcWebp
                    srcSetWebp
                  }
                  fixed(width: 1080) {
                    base64
                    aspectRatio
                    width
                    height
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
