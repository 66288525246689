import React, { Component } from "react";
import { BlogListMiniHero } from "./article-list-mini-hero.component";
import { Link } from "gatsby";

export class BlogListItem extends Component {
  render() {
    const { node } = this.props;
    const { frontmatter } = node;
    const {
      headline,
      date,
      description,
      topic,
      lead_image: leadImage,
      hero,
    } = frontmatter;

    const { slug, readingTime } = node.fields;

    return (
      <Link
        key={slug}
        to={slug}
        className="flex flex-col overflow-hidden group"
      >
        <BlogListMiniHero leadImage={leadImage} hero={hero} />
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            <p className="text-sm font-medium text-pink-600 mb-2">{topic}</p>
            <div>
              <p className="text-xl font-bold text-gray-900 font-sans group-hover:border-pink-600 border-b-2 border-transparent transition duration-300 inline">
                {headline}
              </p>
              <p className="mt-3 text-base text-gray-700">{description}</p>
            </div>
          </div>
          <div className="mt-6 flex items-center">
            <div>
              <div className="flex space-x-1 text-sm text-gray-700">
                <time dateTime={date}>{date}</time>
                <span aria-hidden="true">&middot;</span>
                <span>{readingTime?.text?.replace("read", "")}</span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}
