import React from "react";
import { Separator } from "../separator.element";
import { ArticleList } from "./article-list.component";

export function RelatedArticles({ topic, articles, min = 1 }) {
  if (!(Array.isArray(articles) && articles.length >= min)) {
    return null;
  }

  return (
    <div>
      <Separator className="my-4" text={`Weitere Artikel zum Thema ${topic}`} />
      <ArticleList articles={articles} />
    </div>
  );
}
