import React, { Component } from "react";
import { BlogListItem } from "./article-list-item.component";

export class ArticleList extends Component {
  render() {
    const { articles } = this.props;

    if (!(Array.isArray(articles) && articles.length)) {
      return null;
    }

    return (
      <div className="grid gap-5 md:grid-cols-2 lg:grid-cols-3">
        {articles.map(article => (
          <BlogListItem key={article.node.fields.slug} {...article} />
        ))}
      </div>
    );
  }
}
