import React, { useEffect, useState, Fragment } from "react";
import { IconElement } from "../icon.element";
import { ImageElement } from "../image.element";
import { MarkdownElement } from "../markdown.element";

export function FlyingSalesRep({
  firstName,
  lastName,
  role,
  trivia,
  mission,
  portrait,
  company,
  phoneNumber,
  linkedin,
  email,
  caseOptions,
  caseSolutions,
  fallbackCaseSolution,
  delay = 5000,
}) {
  const [isActive, setActive] = useState(false);
  const [step, setStep] = useState(1);
  const [resolvedCase, setResolvedCase] = useState({});

  useEffect(() => {
    const timer = window.setTimeout(() => setActive(true), delay);
    return () => clearTimeout(timer);
  }, [delay]);

  function selectCaseOption(optionKey) {
    setResolvedCase(
      caseSolutions.find(({ key }) => key === optionKey) || fallbackCaseSolution
    );
    setStep(3);
  }

  return (
    <Fragment>
      {isActive && (
        <div
          className={`fixed inset-0 z-50 transition duration-1000 ${
            isActive ? "block" : "hidden"
          }`}
          role="backdrop"
          onClick={() => step === 1 && setActive(false)}
          style={{ backdropFilter: "blur(7px)" }}
          data-tracking-action-label="flying-sales-rep.backdrop"
          data-tracking-action-value={step === 1 ? "success" : "trap"}
          data-tracking-action-position={`step${step}`}
        ></div>
      )}
      <div
        className={`fixed bottom-0 inset-x-0 z-50 w-full ${
          step === 1 ? " max-w-sm" : " max-w-md"
        } mx-auto flex items-center justify-center  max-h-screen transform transition transition-transform duration-700 ease-in-out ${
          isActive ? " translate-y-0" : " translate-y-full"
        }`}
      >
        <div className="bg-white w-full relative rounded-t-lg shadow-2xl">
          {portrait && (
            <div
              className={`transition transition-transform duration-1000 transform ${
                isActive ? " -translate-y-1/3" : " translate-y-0"
              } w-full flex items-center justify-center`}
            >
              <div
                className={`rounded-full ${
                  step === 3 ? "w-16 h-16" : " w-32 h-32 "
                } overflow-hidden shadow-lg relative`}
              >
                {step !== 3 && (
                  <ImageElement
                    src={portrait}
                    isFixed={true}
                    className="w-full"
                  />
                )}
                {step === 3 && (
                  <div className="absolute inset-0 bg-pink-600 flex justify-center items-center">
                    <div className="text-white text-2xl">
                      <IconElement icon="thumbsUp" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="px-4 sm:px-10 text-sm sm:text-base">
            {step === 1 && (
              <Fragment>
                <p className="text-center">
                  Hallo <span className="animate-bounce">👋</span>
                </p>
                <p className="text-center">
                  Ich bin <strong>{firstName}</strong>, {trivia}. Als {role} bei{" "}
                  {company} ist es meine Mission {mission}.
                </p>
                <p className="text-center">
                  <strong>Dein Besuch hat mich neugierig gemacht.</strong> Darf
                  ich dir <strong>eine kurze Frage</strong> stellen?
                </p>
                <p className="flex flex-col pt-2 pb-8">
                  <button
                    onClick={() => setStep(2)}
                    type="button"
                    className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-bold rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                    data-tracking-action-label="flying-sales-rep.start"
                    data-tracking-action-value="Ja klar, schiess los..."
                    data-tracking-action-position="intro"
                  >
                    Ja klar, schiess los...
                  </button>

                  <button
                    type="button"
                    onClick={() => setActive(false)}
                    className="mt-2 inline-flex items-center justify-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                    data-tracking-action-label="flying-sales-rep.reject"
                    data-tracking-action-value="Nein."
                    data-tracking-action-position="intro"
                  >
                    Nein.
                  </button>
                </p>
              </Fragment>
            )}
            {step === 2 && (
              <Fragment>
                <p className="text-center">
                  Das freut mich. Wie versprochen, nur eine ganz kurze Frage von
                  mir:
                </p>
                <p className="text-center">
                  Wie würdest du{" "}
                  <strong>
                    deine aktuelle Herausforderung am ehesten beschreiben?
                  </strong>
                </p>
                <p className="flex flex-col py-4 max-h-72 md:max-h-screen overflow-y-auto">
                  {Array.isArray(caseOptions) &&
                    caseOptions.map(({ option, key }) => (
                      <button
                        key={key}
                        onClick={() => selectCaseOption(key)}
                        type="button"
                        className="mb-2 inline-flex items-center justify-center px-2.5 py-1.5 border border-transparent text-sm font-bold rounded text-gray-800 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        data-tracking-action-label="flying-sales-rep.challenge"
                        data-tracking-action-value={option}
                        data-tracking-action-position="step2"
                      >
                        {option}
                      </button>
                    ))}

                  <button
                    type="button"
                    onClick={() => selectCaseOption(null)}
                    className="inline-flex items-center justify-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                    data-tracking-action-label="flying-sales-rep.challenge"
                    data-tracking-action-value="OTHER_OPTION_UNKNOWN"
                    data-tracking-action-position="step2"
                  >
                    Ich suche etwas anderes
                  </button>
                </p>
              </Fragment>
            )}
            {step === 3 && (
              <Fragment>
                {resolvedCase && (
                  <Fragment>
                    <MarkdownElement proseMode={false} className="text-center">
                      {resolvedCase.response}
                    </MarkdownElement>

                    <p className="flex flex-col pt-4 max-h-72 md:max-h-screen overflow-y-auto">
                      {Array.isArray(resolvedCase?.followUpCtas) &&
                        resolvedCase.followUpCtas.map(
                          ({ link, text, icon, primary }) => (
                            <a
                              key={link + text}
                              href={link}
                              target="_blank"
                              rel="noopener"
                              className={`mb-2 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-bold rounded-md ${
                                primary
                                  ? "  text-white bg-pink-600 hover:bg-pink-700 focus:ring-pink-500 focus:ring-offset-2"
                                  : " bg-gray-50 hover:bg-gray-100 focus:ring-gray-500"
                              } focus:outline-none focus:ring-2`}
                              data-tracking-action-label="flying-sales-rep.response-cta"
                              data-tracking-action-value={text}
                              data-tracking-action-position="step3"
                            >
                              {icon && (
                                <span
                                  className="inline-block mr-3 -ml-1 text-lg opacity-75"
                                  aria-hidden="true"
                                >
                                  <IconElement icon={icon} />
                                </span>
                              )}

                              <span>{text}</span>
                            </a>
                          )
                        )}
                    </p>

                    {Array.isArray(resolvedCase?.followUpCtas) &&
                      resolvedCase.followUpCtas.length && (
                        <div className="relative my-4">
                          <div
                            className="absolute inset-0 flex items-center"
                            aria-hidden="true"
                          >
                            <div className="w-full border-t border-gray-300" />
                          </div>
                          <div className="relative flex justify-center">
                            <span className="px-2 bg-white text-sm text-gray-700">
                              oder ganz spontan
                            </span>
                          </div>
                        </div>
                      )}

                    <div className="flex">
                      <div className="flex-shrink-0 mr-4 flex-grow-0 mx-auto h-16 w-16 rounded-full overflow-hidden">
                        <ImageElement
                          src={portrait}
                          isFiexed={true}
                          className="w-full"
                          alt={
                            firstName +
                            " " +
                            lastName +
                            " " +
                            role +
                            " @ " +
                            company +
                            " profile picture"
                          }
                        />
                      </div>
                      <div className="flex-grow">
                        <div className="text-base font-medium">
                          <strong className="block leading-6">
                            {firstName} {lastName}
                          </strong>
                          <div className="text-pink-600 text-sm mb-1.5">
                            {role}
                          </div>
                          <div className="text-gray-800 text-sm">
                            <span className="inline-block w-12">Tel</span>{" "}
                            {phoneNumber}
                          </div>
                          <div className="text-gray-800 text-sm">
                            <span className="inline-block w-12">E-Mail</span>{" "}
                            {email}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )}
                <p className="flex flex-col py-4 max-h-72 md:max-h-screen overflow-y-auto">
                  <button
                    type="button"
                    onClick={() => setActive(false)}
                    className="inline-flex items-center justify-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                    data-tracking-action-label="flying-sales-rep.close"
                    data-tracking-action-value="schliessen"
                    data-tracking-action-position="step3"
                  >
                    schliessen
                  </button>
                </p>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
