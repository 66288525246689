import React from "react";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { IconElement } from "../icon.element";

export function ShareMeElement({
  url,
  title,
  description,
  campaign = "share",
  source = "social",
  hashtags = [],
}) {
  const campaignUrl = `${url}?utm_campaign=${campaign}&source=${source}`;
  const cleanDescription =
    description.length > 125 ? description.substr(0, 125) + "..." : description;

  return (
    <ul className="flex space-x-3 items-center">
      <li className="transition duration-200 hover:text-twitter">
        <TwitterShareButton
          title={title}
          via="echonovum"
          hashtags={hashtags}
          url={campaignUrl + "&utm_medium=twitter"}
          children={<IconElement icon="twitter" />}
        />
      </li>
      <li className="transition duration-200 hover:text-linkedin">
        <LinkedinShareButton
          title={title}
          summary={cleanDescription}
          url={campaignUrl + "&utm_medium=linkedin"}
          children={<IconElement icon="linkedin" />}
        />
      </li>
      <li className="transition duration-200 hover:text-facebook">
        <FacebookShareButton
          quote={title}
          url={campaignUrl + "&utm_medium=facebook"}
          children={<IconElement icon="facebookSquare" />}
        />
      </li>
      <li className="transition duration-200 hover:text-reddit">
        <RedditShareButton
          title={title}
          url={campaignUrl + "&utm_medium=reddit"}
          children={<IconElement icon="reddit" />}
        />
      </li>
      <li className="transition duration-200 hover:text-whatsapp">
        <WhatsappShareButton
          title={title}
          url={campaignUrl + "&utm_medium=whatsapp"}
          children={<IconElement icon="whatsapp" />}
        />
      </li>
      <li className="transition duration-200 hover:text-pink-500">
        <EmailShareButton
          subject={title}
          body={cleanDescription}
          url={campaignUrl + "&utm_medium=email"}
          children={<IconElement icon="envelope" />}
        />
      </li>
    </ul>
  );
}
